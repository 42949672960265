:root {
  --container-width: 1170px;
}

body {
  --header-height: 5.9rem;
  --header-border-height: 6px;
  --announcement-height: 0px;
  --page-block-start: calc(var(--header-height) + var(--announcement-height) - var(--header-border-height));
}

@media (min-width: 900px) {
  body {
    --header-height: 5rem;
  }
}
body {
  --aside-inline-padding: 0;
}
@media (min-width: 900px) {
  body {
    --aside-inline-padding: 2rem;
  }
}

:is(h2, h3, h4),
.h1:not(.bordered) {
  color: #4c4d52;
}

h2 {
  margin-block: 0 0.25em;
  font-size: 1.7em;
}

.course-banner-intro:has(.video) .container {
  display: block;
  padding-bottom: 3em;
}
@media (min-width: 760px) {
  .course-banner-intro:has(.video) .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.course-banner-intro:has(.video) .content {
  padding-inline-end: 2em;
}
.course-banner-intro:has(.video) .video {
  width: 100%;
  padding-block: 2em;
}
.course-banner-intro:has(.video) .button-invert {
  color: #4797eb !important;
}

.course-about {
  padding-block: 2em;
}

.course-sections {
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  position: relative;
  min-height: 20em;
  padding-inline: var(--aside-inline-padding);
}
.course-sections .box {
  display: block;
  margin-block: -1em;
  width: 100%;
  padding: 1em 2em;
  overflow-y: scroll;
  position: absolute;
  top: -1em;
  bottom: -1em;
  width: calc(100% - var(--aside-inline-padding) * 2);
}
.course-sections h2 {
  font-size: 1.4em;
  border-bottom: 2px solid #65bef6;
  padding-block-end: 0.5em;
  margin-block-end: 0.5em;
}
.course-sections .stack {
  --space: 1em;
}

.course-section {
  font-size: 0.8em;
  overflow: hidden;
}
.course-section:has(.course-section-video) {
  --space: 3em;
}
.course-section:not(:has(.course-section-video)) {
  border-top: 2px solid #d5efff;
  padding-top: var(--space);
}
.course-section h3 {
  font-size: 1.2em;
  line-height: 1.3;
  margin: 0 0 0.25em;
  font-weight: 400;
}

.course-section-video {
  background-color: #eaf7fe;
  aspect-ratio: auto;
  margin-block-end: 0.75em;
}

.course-section-video .wistia_embed {
  display: block;
  margin-inline: auto;
}

.course-section-details {
  flex: 1;
}

.course-info {
  gap: 2.5em 1em;
}
@media (min-width: 400px) {
  .course-info {
    --grid-item-width: 400px;
  }
}
.course-info .stack {
  --space: 2.5em;
}
.course-info p {
  margin-top: 0;
}
.course-info p + p {
  margin-top: 0.75em;
}
.course-info .media-body {
  flex: 1;
  min-width: auto;
}
.course-info aside {
  font-size: 1em;
}
@media (min-width: 900px) {
  .course-info aside {
    padding-inline: 4rem;
    font-size: 0.8em;
  }
}

.course-info-certification .media-item {
  max-width: 128px;
}

.course-instructor .media-item {
  max-width: 96px;
}
.course-instructor h3 {
  font-size: 1.1em;
  font-weight: bold;
  color: #4c4d52;
}

.fa-ul {
  --fa-li-margin: 2em;
  --fa-li-width: 2.5em;
}

.fa-li {
  color: #00c3a9;
}

li:has(.fa-li) + li:has(.fa-li) {
  margin-top: 0.5em;
}