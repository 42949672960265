@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../base/variables";
@import "../utils/mixins";

// Styles for On Demand Course page

body {
  --aside-inline-padding: 0;

  @include breakpoint($lg) {
    --aside-inline-padding: 2rem;
  }
}

:is(h2,h3,h4),
.h1:not(.bordered) {
  color: $base-font-color;
}
h2 {
  margin-block: 0 .25em;
  font-size: 1.7em;
}


// Banner
// ----------------------------------------------------------------

.course-banner-intro:has(.video) {
  .container {
    display: block;
    padding-bottom: 3em;

    @include breakpoint($med-lg) {
      display: grid;
      grid-template-columns: repeat(2,1fr);
    }
  }
  .content {
    padding-inline-end: 2em;
  }
  .video {
    width: 100%;
    padding-block: 2em;
  }
  .button-invert {
    color: palette(blue) !important;
  }
}


// About Content
// ----------------------------------------------------------------

.course-about {
  padding-block: 2em;
}

.course-sections {
  background-color: rgba(0,0,0,.03);
  display: flex;
  position: relative;
  min-height: 20em;
  padding-inline: var(--aside-inline-padding);

  .box {
    display: block;
    margin-block: -1em;
    width: 100%;
    padding: 1em 2em;
    overflow-y: scroll;
    position: absolute;
    top: -1em;
    bottom: -1em;
    width: calc(100% - var(--aside-inline-padding)*2);
  }

  h2 {
    font-size: 1.4em;
    border-bottom: 2px solid palette(blue, light);
    padding-block-end: .5em;
    margin-block-end: .5em;
  }

  .stack {
    --space: 1em;
  }
  
}
.course-section {
  font-size: 0.8em;
  overflow: hidden;

  &:has(.course-section-video) {
    --space: 3em;
  }

  &:not(:has(.course-section-video)) {
    border-top: 2px solid #d5efff;
    padding-top: var(--space);
  }

  h3 {
    font-size: 1.2em;
    line-height: 1.3;
    margin: 0 0 .25em;
    font-weight: 400;
  }
}

.course-section-video {
  background-color: #eaf7fe;
  aspect-ratio: auto;
  margin-block-end: .75em;
}
.course-section-video .wistia_embed {
  display: block;
  margin-inline: auto;
}

.course-section-details {
  flex: 1;
}

.course-info {
  gap: 2.5em 1em;

  @include breakpoint(400px) {
    --grid-item-width: 400px;
  }

  .stack {
    --space: 2.5em;
  }

  p {
    margin-top: 0;

    & + p {
      margin-top: .75em;
    }
  }

  .media-body {
    flex: 1;
    min-width: auto;
  }


  aside {
    font-size: 1em;

    @include breakpoint($lg) {
      padding-inline: 4rem;
      font-size: 0.8em;
    }
  }
}

.course-info-certification {
  .media-item {
    max-width: 128px;
  }
}

.course-instructor {
  .media-item {
    max-width: 96px;
  }
  h3 {
    font-size: 1.1em;
    font-weight: bold;
    color: $base-font-color;
  }
}

// Icon Lists
.fa-ul {
  --fa-li-margin: 2em;
  --fa-li-width: 2.5em;
}
.fa-li {
  color: palette(teal);
}
li:has(.fa-li) + li:has(.fa-li) {
  margin-top: .5em;
}